<template>
  <div class="container">
    <KTCodePreview v-bind:title="'Thông tin sản phẩm bảo hành'">
      <template v-slot:preview>
        <b-row class="col-md-12 pl-0">
          <b-col cols="3">
            <b-form-group
              label-class="label-input"
              label="Kho:"
            >
              <Autosuggest
                :model="searchStore"
                :suggestions="filteredOptions"
                placeholder="kho"
                :limit="LIMIT_SEARCH_ITEM"
                @select="onSelectedStore"
                @change="onInputChange"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Ghi chú:"
              label-class="label-input"
            >
              <b-form-textarea
                size="sm"
                v-model="notes"
                :placeholder="'Thêm nội dung ghi chú...'"
                :rows="NOTE_ROWS"
                :max-rows="NOTE_MAX_ROWS"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="col-md-12 pl-0">
          <b-col cols="6">
            <b-form-group
              label-class="label-input"
              label="Tìm sản phẩm:"
            >
              <Autosuggest
                :model="searchProduct"
                :suggestions="filteredOptionsProduct"
                placeholder="sản phẩm"
                :limit="LIMIT_SEARCH_ITEM"
                @select="onSelectedProduct"
                @change="onInputChangProduct"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          class="col-md-12 pl-0"
          v-if="listProductStock.length"
        >
          <b-col>
            <b-form-group
              class="label-input"
              label="Danh sách sản phẩm"
            >
              <b-table
                class="table-bordered table-hover col-md-12"
                :fields="fields"
                :items="listProductStock"
              >

                <template v-slot:cell(name)="row">
                  <span v-if="row.item.productType === PRODUCT_TYPE.PRODUCT_IMEI 
                    && !row.item.imeiCode">
                    <p class="mb-0">{{ row.item.name }}</p>
                    <p
                      @click="onSelectImei(row.item)"
                      class="cursor-pointer text-primary mb-0"
                    >Danh sách IMEI</p>
                  </span>
                  <p
                    v-else
                    class="mb-0"
                  >{{ row.item.name }}
                    <span
                      class="text-primary font-italic"
                      v-if="row.item.imeiCode"
                    >
                      ({{row.item.imeiCode}})
                    </span>
                  </p>
                </template>

                <template v-slot:cell(quantity)="row">
                  <b-form-input
                    v-if="row.item.productType === PRODUCT_TYPE.PRODUCT"
                    size="sm"
                    v-model="row.item.quantity"
                    placeholder="Nhập SL"
                    class="text-right"
                    v-mask="mask"
                  ></b-form-input>
                  <span v-else>{{ row.item.quantity }}</span>
                </template>
                <template v-slot:cell(actions)="row">
                  <v-icon
                    small
                    class="text-danger text-center"
                    @click="deleteItemInArray(row.item.id)"
                    v-b-tooltip
                    title="Xóa"
                  >mdi-delete</v-icon>
                </template>
              </b-table>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mb-5 mt-5 submit-container">
          <b-button
            :disabled="!listProductStock.length || stockId"
            variant="primary"
            @click="createStock"
          >
            Lưu
          </b-button>
          <router-link
            to="/complete-warranty-stock"
            tag="button"
          >
            <b-button variant="secondary">Hủy</b-button>
          </router-link>
        </div>
      </template>
    </KTCodePreview>
    <b-modal
      ref="list-imei-modal"
      hide-footer
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <b-table
          :items="listImeiByProduct"
          :fields="fieldListImei"
          bordered
          hover
          class="col-md-12"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="3"
            ></vcl-table>
          </template>
          <template v-slot:cell(imeiCode)="row">
            <div class="d-flex justify-content-center">
              <span v-text="row.item.imeiCode"></span>
            </div>
          </template>
          <template v-slot:cell(sellingPrice)="row">
            <div>
              <span
                v-text="formatPrice(row.item.sellingPrice)"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <div class="d-flex justify-content-center">
              <span
                v-text="checkStatus(row.item.status)"
                v-bind:class="bindClassNameStatus(row.item.status)"
              ></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="applyImei(row.item)"
                v-b-tooltip
                title="Áp dụng"
              >mdi-pen</v-icon>
            </div>
          </template>
        </b-table>
        <b-button
          class="mx-2 fw-bold w-100"
          variant="secondary"
          size="sm"
          @click="hideModalListImei"
        >Hủy</b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<style lang="css" scoped>
.container /deep/ .label-input {
  padding: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  color: #3f4254;
}
.submit-container button {
  width: 80px;
  font-weight: 500;
}

.submit-container button:last-child {
  margin-left: 10px;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { v4 } from 'uuid';
import {
  currencyMask,
  unMaskPrice,
  removeAccents,
  makeToastSuccess,
  makeToastFaile,
  formatPrice,
} from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { PRODUCT_TYPE } from '@/utils/enum';
import { IMEI_STATUS } from '../../../utils/enum';

const LIMIT_SEARCH_ITEM = 10;
const NOTE_ROWS = 5;
const NOTE_MAX_ROWS = 5;
export default {
  components: {
    Autosuggest,
    KTCodePreview,
  },
  mixins: [validationMixin],
  data() {
    return {
      stockId: null,
      selectingProduct: null,
      listImeiByProduct: [],
      PRODUCT_TYPE,
      LIMIT_SEARCH_ITEM,
      NOTE_ROWS,
      NOTE_MAX_ROWS,
      totalPrice: 0,
      listProduct: [],
      listProductSearch: [],
      filteredOptionsProduct: [],
      optionsProduct: [],
      searchProduct: '',
      selectTypeSearch: 1,
      listProductStock: [],
      filteredOptions: [],
      options: [],
      searchStore: '',
      storeId: null,
      filteredOptionsProductNew: [],
      optionsProductNew: [],
      searchProductNew: '',
      isSearching: false,
      notes: '',
      currentIndex: null,
      mask: currencyMask,
      fields: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'align-middle',
        },
        {
          key: 'name',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right align-middle',
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return formatPrice(value);
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center align-middle',
        },
      ],
      fieldListImei: [
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  validations: {},
  async created() {
    await this.fetchStore();
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Danh sách phiếu hoàn tất bảo hành',
        route: '/complete-warranty-stock',
      },
      { title: 'Thông tin sản phẩm bảo hành' },
    ]);
  },
  methods: {
    getStockById() {
      this.stockId = this.$route.query.id;
      if (!this.stockId) {
        return;
      }
      ApiService.get(`stocks/transfer-stock/${this.stockId}`).then(
        ({ data }) => {
          let dataRes = data.data;
          this.storeId = dataRes.sourceStoreId;
          this.notes = dataRes.description;
          const store = this.options.find((item) => item.id === this.storeId);
          this.searchStore = store ? store.name : '';

          dataRes.listDetail.forEach((element) => {
            const dataItem = {
              id: element.id,
              name: element.productName,
              productCode: element.productCode,
              barCode: element.barCode,
              imeiCode: element.productImei.replace(', ', '\n'),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              totalQuantityInStock: 0,
            };
            this.listProductStock.push(dataItem);
          });
        },
      );
    },
    checkStatus: function (item) {
      switch (item) {
        case IMEI_STATUS.TRANSFERING:
          return 'Đang chuyển kho';
        case IMEI_STATUS.ON_WARRANTY:
          return 'Đang bảo hành';
        default:
          return '';
      }
    },
    bindClassNameStatus(status) {
      let className = '';
      switch (status) {
        case IMEI_STATUS.TRANSFERING: {
          className = 'label-light-warning';
          break;
        }
        case IMEI_STATUS.EXPORT_WARRANTY: {
          className = 'label-light-danger';
          break;
        }
      }
      return className;
    },
    hideModalListImei() {
      this.$refs['list-imei-modal'].hide();
    },
    applyImei(item) {
      const updateIndex = this.listProductStock.findIndex(
        (item) => item.id === this.selectingProduct.id,
      );
      if (updateIndex > -1) {
        this.listProductStock[updateIndex].imeiCode = item.imeiCode;
      }
      this.hideModalListImei();
    },
    getListImei(productId) {
      if (!productId || !this.storeId) {
        return;
      }
      const param = {
        productId: productId,
        storeId: this.storeId,
        imeiStatus: IMEI_STATUS.ON_WARRANTY,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('imeis/get-by-product', paramQuery).then(({ data }) => {
        this.listImeiByProduct = [];
        const keyByProductImei = {};
        this.listProductStock.forEach((item) => {
          const key = item.proId + '-' + item.imeiCode;
          keyByProductImei[key] = true;
        });
        data.data.forEach((element) => {
          if (keyByProductImei[element.productId + '-' + element.imeiNo]) {
            return;
          }
          const item = {
            id: element.id,
            imeiCode: element.imeiNo,
            originalPrice: element.originalPrice ? element.originalPrice : 0,
            sellingPrice: element.sellingPrice ? element.sellingPrice : 0,
            status: element.status,
          };
          this.listImeiByProduct.push(item);
        });
      });
    },
    onSelectImei(product) {
      this.selectingProduct = product;
      this.getListImei(product.proId);
      this.$refs['list-imei-modal'].show();
    },
    formatPrice,
    removeAccents,
    onSelectedProduct(option) {
      let quantity = 0;
      const selectedItem = option.item;
      this.searchProduct = selectedItem.productName;
      const product = {
        id: v4(),
        name: selectedItem.name,
        productCode: selectedItem.productCode,
        totalQuantityInStock: selectedItem.totalQuantityInStock,
        imeiCode: '',
        proId: selectedItem.id,
        price: selectedItem.sellingPrice,
        quantity: 1,
        discount: 0,
        totalPrice: quantity * selectedItem.sellingPrice,
        productType: selectedItem.productType,
      };
      this.listProductStock.push(product);
      this.searchProduct = '';
    },
    onInputChangProduct(text = '') {
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    validate(data) {
      if (!data.listProduct.length) {
        return { valid: false, message: 'Vui lòng chọn sản phẩm!' };
      }

      const isValid = data.listProduct.every((element) => {
        if (element.productType === PRODUCT_TYPE.PRODUCT_IMEI) {
          if (!element.imeiCode) {
            return { valid: false, message: `Vui lòng nhập mã imei!` };
          }
          if (!element.imei) {
            return { valid: false, message: `Vui lòng nhập mã imei mới!` };
          }
        }
        return true;
      });

      return { valid: isValid, message: '' };
    },
    createStock: async function () {
      const listPro = this.listProductStock.map((element) => {
        return {
          productId: element.proId,
          productType: element.productType,
          imeis: element.imeiCode,
          newImeiCode: element.imei,
          quantity: unMaskPrice(element.quantity),
          unitPrice: unMaskPrice(element.price),
          discount: 0,
        };
      });
      const data = {
        storeId: this.storeId,
        providerId: null,
        description: this.notes,
        importDate: null,
        listProduct: listPro,
        cashAmount: 0,
        transferAmount: 0,
        transferAccountCode: null,
        payDate: null,
        vatDate: null,
        vatType: null,
        vatAmount: 0,
        vatCode: null,
        discountType: null,
        discountAmount: 0,
        purchaseOrderId: null,
        purchaseOrderCode: null,
        stockSlipType: null,
      };
      const validateResult = this.validate(data);
      if (!validateResult.valid) {
        return makeToastFaile(validateResult.message);
      }
      ApiService.post('stock-slips/warranty-completion-slip', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.$router.push({
              name: 'complete-warranty-stock',
            });
          } else {
            makeToastFaile(data.message);
            this.isNew = true;
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
            this.isNew = true;
          }
        });
    },
    fetchStore: async function () {
      await ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        data.data.list_store.map((element) => {
          const store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options.push(store);
        });
        this.filteredOptions = [...this.options];
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      const params = new URLSearchParams({
        storeId: this.storeId,
        searchProduct: textSearch,
        checkQuantityType: 'warrantyQuantity',
      });
      ApiService.get(`stocks/get-product-by-store?${params.toString()}`)
        .then(({ data }) => {
          this.isSearching = false;
          this.filteredOptionsProduct = data.data.map((element) => ({
            ...element,
            name: element.productName,
          }));
        })
        .catch(() => (this.isSearching = false));
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    fetchProductNew(textSearch = '') {
      this.isSearching = true;
      const params = new URLSearchParams({
        storeId: this.storeId,
        searchProduct: textSearch.trim(),
        checkQuantityType: 'warrantyQuantity',
      });
      ApiService.get(`stocks/get-product-by-store?${params.toString()}`)
        .then(({ data }) => {
          this.filteredOptionsProductNew = data.data.map((element) => ({
            ...element,
            name: element.productName,
          }));
          this.isSearching = false;
        })
        .catch(() => (this.isSearching = false));
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.storeId = option.item.id;
    },
    isMatchSearch(searchText, value) {
      return removeAccents(value).toLowerCase().indexOf(searchText) > -1;
    },
    onInputChange(text = '') {
      this.searchStore = text;
      const filteredData = this.options
        .filter((item) => {
          return (
            this.isMatchSearch(text, item.name) ||
            this.isMatchSearch(text, item.shortName)
          );
        })
        .slice(0, LIMIT_SEARCH_ITEM);
      this.filteredOptions = [...filteredData];
    },
    searchProductAPI() {
      if (!this.isSearching) {
        this.fetchProduct(this.searchProduct);
      }
    },
    searchProductAPINew() {
      if (!this.isSearching) {
        let textSearch = this.searchProductNew;
        this.fetchProductNew(textSearch);
      }
    },
    debounceInputProduct: debounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    newId() {
      return v4();
    },
    onChangeProduct(selectedItem) {
      this.currentIndex = this.listProductStock.findIndex(
        (item) => item.id == selectedItem.id,
      );
    },
    onInputChangeProductNew(text = '') {
      this.searchProductNew = text;
      this.debounceInputProductNew();
    },
    debounceInputProductNew: debounce(function () {
      this.searchProductAPINew();
    }, TIME_TRIGGER),
    onSelectedProductNew(option) {
      this.searchProductNew = option.item.productName;
      this.listProductStock[this.currentIndex].productIdNew =
        option.item.productId;
      this.listProductStock[this.currentIndex].productNameNew =
        option.item.productName;
    },
  },
};
</script>
